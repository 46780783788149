import { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [deck, setDeck] = useState(null);
  const [cards, setCards] = useState(null);

  useEffect(() => {
    const fetchDeck = async () => {
      const response = await fetch("https://anki-api.pages.dev/data");
      const data = await response.json();
      setDeck(data);
    };
    fetchDeck();
  }, []);

  // post request
  const addCard = async () => {
    const data = {
      id: 8,
      title: "Other ANKI Decks",
      description: "Collection of decks to learn english. American, British, Grammar, Dictionary, etc.",
      notes: 23020,
      imgs: ["./assets/id-1/front.png", "./assets/id-1/back.png"],
      details: "",
      download: "https://drive.google.com/drive/folders/1BUjcCzP0P9qu4xirQuvcAnwBsp6gU6Pk?usp=sharing",
    };
    const response = await fetch("https://anki-api.pages.dev/data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  addCard();

  const fetchDeck = async () => {
    const response = await fetch("https://anki-api.pages.dev/data");
    const data = await response.json();
    setDeck(data);
  };
  console.log(deck);
  return <div className="App"></div>;
}

export default App;
